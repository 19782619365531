import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  address?: string
  addressImage?: ImageProps
  googleMapsURL?: string
  languageCode: string
  mapImage?: ImageProps
  siteName?: string
  variant?: Variant
}

export const Head = memo(function Head({
  address,
  addressImage,
  googleMapsURL,
  languageCode,
  mapImage,
  siteName,
  variant = 'default',
}: Props) {
  return (
    <Container row wrap variant={variant}>
      <LeftSide>
        {mapImage ? (
          <LazyLoadComponent>
            <Image {...mapImage} />
          </LazyLoadComponent>
        ) : null}
      </LeftSide>
      <RightSide>
        {addressImage ? (
          <LazyLoadComponent>
            <Image {...addressImage} />
          </LazyLoadComponent>
        ) : null}
        <Wrapper>
          {siteName ? <SiteName>{siteName}</SiteName> : null}
          {address ? <Address>{address}</Address> : null}
          {googleMapsURL ? (
            <CTA
              label={useVocabularyData('how-to-reach-us', languageCode)}
              rel="noopener"
              target="_blank"
              variant="invert"
              URL={googleMapsURL}
            />
          ) : null}
        </Wrapper>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)<ContainerProps>`
  transform: translateY(-3.75rem);

  > div {
    width: 50%;
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    overflow: hidden;
    padding-bottom: 36.5%;
    position: relative;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'cta':
        return css`
          margin-bottom: 3.75rem;
          transform: none;
          &:before {
            content: '';
            width: 150vw;
            height: 40%;
            background: ${theme.colors.variants.neutralLight1};
            position: absolute;
            top: 0;
            left: -50vw;
          }
        `
      case 'slider':
        return css`
          margin-bottom: 3.75rem;
          padding-top: 10.875rem;
          transform: none;
          &:before {
            content: '';
            width: 150vw;
            height: calc(40% + 10.875rem);
            background: ${theme.colors.variants.neutralLight2};
            position: absolute;
            top: 0;
            left: -50vw;
          }
        `
    }
  }}

  @media (max-width: 1023px) {
    transform: none;

    > div {
      width: 100%;
    }
  }
`

const LeftSide = styled.div`
  @media (max-width: 1023px) {
    padding-bottom: 70% !important;
  }
`

const RightSide = styled.div`
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.primaryDark, 0.3)},
      ${rgba(theme.colors.variants.primaryDark, 1)}
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 1023px) {
    padding: 3.5625rem 2rem !important;
    text-align: center;
  }
`

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  position: absolute;
  bottom: 4.125rem;
  right: 4.5rem;
  left: 4.5rem;
  z-index: 2;

  @media (max-width: 1023px) {
    position: relative;
    bottom: auto;
    right: auto;
    left: auto;
  }
`

const SiteName = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
`

const Address = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 0.1875rem;
`

const CTA = styled(Button)`
  margin-top: 1.875rem;
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'cta' | 'slider'
